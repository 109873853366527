import React, { Component } from "react";
import SEO from "../components/seo";
import { Flex, Box } from "@rebass/grid";

import Title from "../components/page-title";
import Copy from "../components/page-copy";
import Row from "../components/page-content-row";
import Image from "../components/page-image-new";
//import PageOverview from '../components/page-overview';
import PageResourceSide from "../components/page-resources-side";

import Image1 from '../images/resources/happy_haus_darlington_047.jpg'
const ogImages = [`https://happyhaus.com.au${Image1}`]


const PostImage = "resources/happy_haus_darlington_047.jpg";

class Page extends Component {
  render() {
    return (
      <>
        <SEO title="The Happy Haus approach to designing" description="Our business is built on the knowledge that the housing market in Australia is overdue for some innovation. And on the
                  understanding that homes on any budget can be designed well and built with care through a process that gives equal
                  consideration to design and cost outcomes. We love to build homes that are truly great places to be, places for rich and
                  fulfilling lives." images={ogImages}  />

        <Row>
          <Flex>
            <Box width={8 / 12} px={2}>
              <Image src={PostImage} ratio={9 / 16} margin={true} />
              <Title align="left">The Happy Haus approach to designing</Title>
              <Copy align="left">
                <p>
                  Our business is built on the knowledge that the housing market in Australia is overdue for some innovation. And on the
                  understanding that homes on any budget can be designed well and built with care through a process that gives equal
                  consideration to design and cost outcomes. We love to build homes that are truly great places to be, places for rich and
                  fulfilling lives. We create spaces that are beautiful yet practical, engage with their surrounds and maximise the ability
                  to enjoy the benefits of life in the subtropics.
                </p>
                <p>
                  We believe in getting the budget right from the start. Our architects and builders work hand-in-hand to ensure we design
                  and build the best home for the available budget.
                </p>
                <p>How can we do this?</p>
                <ol>
                  <li>Our architecture and building teams work together on every project from day one.
                  </li>
                  <li>
                    We strike a balance between design, quality and cost by working with our clients to understand what is most important
                    to them
                  </li>
                  <li>
                    We cost check our projects at every major stage and get real time feedback on design decisions to avoid any surprises
                  </li>
                  <li>We provide fixed price building contracts</li>
                  <li>We work with trusted consultants who understand what it takes to design and build a Happy Haus project.</li>
                </ol>
                <p>
                  We are doing architecture differently. At Happy Haus creativity and innovation are in our DNA. Founded through a
                  collaboration between leading Australian architectural firm, Donovan Hill, and local developer, Toby Lewis, the goal for
                  Happy Haus has always been to disrupt the traditional model of design and construction, which can be costly,
                  time-consuming and come with no guarantees. All of our effort is designed to make better homes available for more people.
                </p>
              </Copy>
            </Box>
            <Box width={4 / 12} px={2} pl={[0, 50]}>
              <PageResourceSide />
            </Box>
          </Flex>
        </Row>
      </>
    );
  }
}

export default Page;
